import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layout'
import config from '../../data/SiteConfig'
import discordpng from "../../static/images/discord.png"
import githubpng from "../../static/images/github.png"
const ContactPage = () => (
  <Layout>
    <main>
      <Helmet title={`Contact | ${config.siteTitle}`} />
      <h1>Contact us</h1>
      <p>We are keeping our discussions on the Discord <a href="https://discord.com/channels/837912332851347476/837912332851347479" target="_blank">Join us</a></p>
      
      <a href="https://discord.com/channels/837912332851347476/837912332851347479" target="_blank"><img src={discordpng} alt="Discord" width='300px' height='100px'/></a>
      <p>We are keeping our work on the Github <a href="https://github.com/DevTuple/" target="_blank">Contribute with us</a></p>
      
      <a href="https://github.com/DevTuple/" target="_blank"><img src={githubpng} alt="Discord" width='300px' height='100px'/></a>
    </main>
  </Layout>
)
export default ContactPage
